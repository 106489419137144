import { render, staticRenderFns } from "./ani_Loading.vue?vue&type=template&id=2499fc31&scoped=true&"
import script from "./ani_Loading.vue?vue&type=script&lang=js&"
export * from "./ani_Loading.vue?vue&type=script&lang=js&"
import style0 from "./ani_Loading.vue?vue&type=style&index=0&id=2499fc31&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2499fc31",
  null
  
)

export default component.exports