import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import CoreuiVueCharts from '@coreui/vue-chartjs'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'
import Axios from 'axios'
import VueProgressBar from 'vue-progressbar'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(CoreuiVueCharts)
Vue.prototype.$log = console.log.bind(console)

// setup progress bar plugin
const progressBarStyle = 
  {
  color : '#73B688',
  failedColor : '#BE3420',
  thickness : '5px',
  transition : 
    {
    speed : '0.2s',
    opacity : '0.6s',
    termination: 300
    },
  autoRevert : true,
  location : 'top',
  inverse : false
  }
Vue.use(VueProgressBar, progressBarStyle)

// add Axios to Vue and if there is a token set Axios to use it for all future calls
Vue.prototype.$http = Axios;
const accessToken = localStorage.getItem('accessToken')
if (accessToken) 
  {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + accessToken
  }

// We need to figure out what the api base url is going to be based on the hostname used to grab
// the frontend webpages. If it's localhost then they are doing development locally and the hostname
// will be VUE_APP_DEV_BACKEND_BASE_URL found in .env
// If it's not localhost then the pages are coming from our staging or production servers

let hname = window.location.hostname.toLowerCase();

//Testing on localhost
if (window.location.hostname === 'localhost') 
  {
  Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_DEV_BACKEND_BASE_URL;
  }
//Subdomain, or main domain
else if(hname.includes('controlbyweb.cloud'))
  {
  // fix problems with 'www' in front of the domain name, by removing 'www' from hname
  hname = hname.replace('www.', '');
  let parts = hname.split("controlbyweb.cloud");
  let URL = window.location.protocol + "//" + parts[0] + "api.controlbyweb.cloud";
  Vue.prototype.$http.defaults.baseURL = URL;
  }
//Third party domain
else
  {
  let URL = window.location.protocol + "//api." + hname.replace('www.', '');
  Vue.prototype.$http.defaults.baseURL = URL;
  }

Number.prototype.pad = function (size) 
  {
  let s = String(this);
  while (s.length < (size || 2)) 
    {
    s = "0" + s;
    }
  return s;
  }

Vue.mixin(
  {
  data:function () 
    {
    return(
      {
      progressCounter: 0,
      progressTimer  : null
      });
    },
  methods: 
    {  
    refreshPage:function()
      {
      this.$router.go(0)
      },  
    // this function requests the necessary cookies and then opens the url for the remote device page in a new tab
    openRemoteDevicePage:function (param)
      {
      this.$http.post('api/v1/accounts/'+this.$store.state.activeAccountID+'/devices/AccessLink', "url=" + param).then(resp => 
        {
        if (resp.data.message !== undefined) 
          {
          if (resp.data.message === "success") 
            {
            window.open(Vue.prototype.$http.defaults.baseURL + '/AccessLink/' + resp.data.token,'_blank');
            }
          }
        })
      .catch(error => 
        {
        this.failProgressBar()
        console.log(error.response);
        })
      },
    axiosGet:function (url, showProgressBar = true) 
      {
      showProgressBar ? this.showProgressBar() : null
      return this.$http.get(url).then((response) => 
        {
        this.hideProgressBar()        
        return response
        })
      .catch(error => 
        {
        this.failProgressBar()        
        this.axiosError = true;
        if (error.response) 
          {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // the error response doesn't contain a direct status code. we must search for it
          if (error.response.status == 401) 
            {
            this.$router.push('/pages/login')
            }
          } 
        else if (error.request) 
          {
          console.log(error.request);
          }
        return error
        })
      },
    axiosPost : function (url) 
      {
      this.showProgressBar()
      return this.$http.post(url).then((response) => 
        {
        this.hideProgressBar()
        return response
        })
      .catch(e => 
        {
        this.failProgressBar()
        this.axiosError = true;
        return e.response
        })
      },
    axiosDelete : function (url) 
      {
      this.showProgressBar()
      return this.$http.delete(url).then((response) => 
        {
        this.hideProgressBar()
        return response
        })
      .catch(e => 
        {
        this.failProgressBar()
        this.axiosError = true;
        return e.response
        })
      },
    showProgressBar : function () 
      {
      if (this.progressCounter <= 0) 
        {
        this.$Progress.start()
        }
      this.progressCounter++
      },
    hideProgressBar : function () 
      {
      if (this.progressCounter > 0) 
        {
        this.progressCounter--
        }
      if (this.progressCounter <= 0) 
        {
        if (this.progressTimer == null) 
          {
          this.progressTimer = setTimeout(() => 
            {
            if (this.progressCounter <= 0) 
              {
              this.$Progress.finish()
              } 
            clearTimeout(this.progressTimer)
            this.progressTimer = null
            }, 500);
          }
        }
      },
    failProgressBar : function () 
      {
      if (this.progressCounter > 0) 
        {
        this.progressCounter--
        }
      if (this.progressCounter <= 0) 
        {
        if (this.progressTimer == null) 
          {
          this.progressTimer = setTimeout(() => 
            {
            if (this.progressCounter <= 0) 
              {
              this.$Progress.finish()
              }
            clearTimeout(this.progressTimer)
            this.progressTimer = null
            }, 500)
          }
        }
      }
    }
  })

new Vue(
  {
  el : '#app',
  router,
  store,
  icons,
  template  : '<App/>',
  components: 
    {
    App
    },
  data() 
    {
    return(
      {
      axiosError: false
      });
    }
  })
